import React from "react";
import { ContentSection, HeaderSection } from "../docs/sections";
import { DocPageMeta } from "../docs";
import { TextContainer } from "../../lib/src";
import { Markdown } from "../../lab/src";

// eslint-disable-next-line import/no-unresolved, import/extensions
import labChangelog from "!!raw-loader!../../lab/CHANGELOG.md";

export const Meta: DocPageMeta = {
	category: "Developing",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection title="Changelog" subTitle="for FancyLab" />
			<ContentSection>
				<TextContainer>
					<Markdown>{labChangelog}</Markdown>
				</TextContainer>
			</ContentSection>
		</>
	);
};
